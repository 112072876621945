import React, { useState } from "react";
import { AddIcon, CloseIcon, PdfFileIcon } from "../../Assets/assets";
import { isValidArray } from "../../Utils/constants";
import "./ResumeUpload.css";
import Button from "../Button/Button";
import {
  getDocumentId,
  updateFileURLToCurrentUser,
} from "../../Services/database";
import { getFileUrl, uploadReferencePicture } from "../../Services/storage";

export default function ResumeUpload(props) {
  const [loading, setLoading] = useState(false);
  const handleFileUpload = async () => {
    setLoading(true);
    try {
      const documentId = await new Promise((resolve, reject) => {
        getDocumentId(props.phoneNumber).then(resolve).catch(reject);
      });

      const extension = getFileExtension(props.file.document?.[0]);

      const URL = await new Promise((resolve, reject) => {
        uploadReferencePicture(
          props.file.document?.[0],
          `${documentId}.${extension}`
        )
          .then(resolve)
          .catch(reject);
      });
      await new Promise((resolve, reject) => {
        updateFileURLToCurrentUser(documentId, URL).then(resolve).catch(reject);
      });
      const downloadURL = await new Promise((resolve, reject) => {
        getFileUrl(URL).then(resolve).catch(reject);
      });
      props.setDownloadURL(downloadURL);
      props.setShowScreen({ success: true, upload: false });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="display-flex flex-direction-column padding-larger">
        <div className="padding-bottom-large text-align-center">
          UPLOAD YOUR RESUME
        </div>
        <UploadFile
          setSnackBarData={(data) => {
            console.log("first,dat", data);
            props.setSnackBarData(data);
          }}
          file={props.file}
          setFile={props.setFile}
          removeImageOnClick={props.removeImageOnClick}
        />
        <div className="padding-left-default padding-top-default font-size-small text-align-center">
          Supported formats are PDF,DOC,DOCX. File size should be below 4MB.
        </div>
        <div className="padding-top-large padding-bottom-large">
          <Button
            loading={loading}
            data-cy="resume-upload-button"
            text="UPLOAD"
            onClick={(event) => {
              event.preventDefault();
              handleFileUpload();
            }}
            disabled={props.file.document.length > 0 ? false : true}
            boxShadow={false}
          />
        </div>
      </div>
    </>
  );
}

function getFileExtension(file) {
  if (file) {
    const fileName = file.name;
    const extension = fileName.split(".").pop().toLowerCase();
    return extension;
  } else {
    return null;
  }
}

const UploadFile = (props) => {
  return (
    <div
      style={{
        height: "56px",
      }}
      data-cy="upload-input-field"
      className="display-flex hide-scrollbar flex-align-items-center inherit-parent-width flex-justify-content-center"
    >
      {isValidArray(props.file?.document) &&
        props.file.document?.map((data, index) => (
          <div
            key={index}
            style={{ height: "56px" }}
            className="position-relative"
            data-cy={`uploaded-pdf-thumbnail`}
          >
            <div
              onClick={() => {
                props.removeImageOnClick(index);
              }}
              data-cy="remove-uploaded-image-button"
              className="background-color-grey width-fit-content border-radius-100-percentage flex-align-items-center flex-justify-content-center position-absolute right-0 opacity-60-percent"
            >
              <CloseIcon />
            </div>
            <div
              style={{
                height: "56px",
                width: "56px",
                backgroundColor: "#757575",
              }}
              className="border-radius-default font-color-white flex-align-items-center flex-justify-content-center"
            >
              <PdfFileIcon height={40} width={40} />
            </div>
          </div>
        ))}
      {console.log(props.file?.document?.length)}
      {props.file?.document?.length === 0 && (
        <form
          className="inherit-parent-width flex-justify-content-center"
          onChange={(event) => {
            if (event.target.files) {
              if (
                typeof event.target.files[0]?.size === "number" &&
                event.target.files[0].size > 5000000
              ) {
                return props.setSnackBarData({
                  code: "input/file-size-exceeded",
                  message: { english: "Document size should be less than 5MB" },
                });
              }
              if (
                [
                  "application/pdf",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ].includes(event.target.files[0]?.type) === true
              ) {
                props.setFile({
                  ...props.file,
                  document: [event.target.files[0]],
                });
              } else {
                return props.setSnackBarData({
                  code: "input/invalid-file",
                  message: { english: "Only PDF,DOC,DOCX are supported" },
                });
              }
            }
          }}
        >
          <label>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              name={props.inputName ? props.inputName : "fileUploaderInput"}
              onClick={(event) => {
                event.target.value = "";
              }}
              className="visibility-hidden position-absolute"
            />
            <div
              data-cy="upload-add-field"
              style={{ width: "56px", height: "56px" }}
              className="display-flex flex-align-items-center flex-justify-content-center black-dashed-border border-radius-default cursor-pointer"
            >
              <AddIcon color="#757575" />
            </div>
          </label>
        </form>
      )}
    </div>
  );
};
