import { Route, Navigate, useNavigate, Routes } from "react-router-dom";
import Home from "./Screens/Home/Home";
import { useEffect, useState } from "react";
import { useLanguage } from "./Components/LanguageController/LanguageController";
import { logRocketInit } from "./Utils/constants";
import LogRocket from "logrocket";
import SnackBar from "./Components/SnackBar/SnackBar";

function App(props) {
  const [selectedLanguage, setSelectedLanguage] = useState("tamil");
  const [status, setStatus] = useState(null);
  const [snackBarData, setSnackBarData] = useState({
    message: null,
    code: null,
  });
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const languageData = useLanguage(selectedLanguage);

  const getWindowSize = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", getWindowSize);
    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
    // eslint-disable-next-line
  }, []);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logRocketInit.key) {
        LogRocket.init(logRocketInit.key, release);
      }
    }
  }, []);

  //for snack bar messages
  useEffect(() => {
    if (snackBarData.message) {
      setStatus(snackBarData.message.english);
    } else {
      setStatus(null);
    }
  }, [snackBarData]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              navigate={navigate}
              setSnackBarData={setSnackBarData}
              windowInnerWidth={windowInnerWidth}
              languageData={languageData}
              setSelectedLanguage={setSelectedLanguage}
              selectedLanguage={selectedLanguage}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <SnackBar
        message={status}
        status={snackBarData}
        type={snackBarData?.code === null ? "success" : "error"}
      />
    </>
  );
}

export default App;
