import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import arunNehruProfileImage from "../../Assets/arunNehruProfileImage.svg";
import {
  AddressIcon,
  EmailIcon,
  LanguageIcon,
  ShareIcon,
  // PhoneIcon,
} from "../../Assets/assets";
import "./Home.css";
import OTPLogin from "../../Components/OTPLogin/OTPLogin";
import AadhaarOTPLogin from "../../Components/AadhaarOTPLogin/AadhaarOTPLogin";
import SuccessScreen from "../../Components/SuccessScreen/SuccessScreen";
import { checkAccountExistAndIsResumePresent } from "../../Services/database";
import ResumeUpload from "../../Components/ResumeUpload/ResumeUpload";

import mapImage from "../../Assets/map.png";
import image1TamMob from "../../Assets/carsole 3 mob.webp";
import image1TamPc from "../../Assets/carsole 3 pc.webp";
import image1mob from "../../Assets/carsole 1 pc.webp";
import image1pc from "../../Assets/carsole 1 mob.webp";
import image2 from "../../Assets/carsole 2.jpeg";
import { citizen, downloadFile } from "../../Utils/constants";
import { getFileUrl } from "../../Services/storage";

export default function Home(props) {
  const [isAuth, setIsAuth] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [formData, setFormData] = useState({
    OTP: null,
    phoneNumber: "+91",
  });
  const [aadhaarFormData, setAadhaarFormData] = useState({
    aadhaarNumber: "",
    OTP: null,
  });
  const [showScreen, setShowScreen] = useState({
    success: false,
    upload: false,
  });

  const [isDataExists, setIsDataExists] = useState({
    exists: null,
    resume: null,
  });
  const [file, setFile] = useState({
    document: [],
  });

  const [downloadURL, setDownloadURL] = useState(null);

  useEffect(() => {
    setIsChecking(true);
    const checkProfileExistence = async () => {
      const data = await new Promise((resolve, reject) => {
        checkAccountExistAndIsResumePresent(formData.phoneNumber)
          .then(resolve)
          .catch(reject);
      });
      setIsDataExists(data);
      if (data.resume) {
        const downloadURL = await new Promise((resolve, reject) => {
          getFileUrl(data.resume).then(resolve).catch(reject);
        });
        setDownloadURL(downloadURL);
      }
      if (data.exists && !data.resume) {
        setShowScreen({ success: false, upload: true });
      }
      if (data.exists && data.resume) {
        setShowScreen({ success: true, upload: false });
      }
      setIsChecking(false);
    };

    if (isAuth) {
      checkProfileExistence();
    }
  }, [formData.phoneNumber, isAuth]);

  return (
    <div className="inherit-parent-height inherit-parent-width">
      <div className="adaptive-parent-height adaptive-padding-main adaptive-flex">
        <div
          style={{
            background:
              "linear-gradient(104.036deg, rgb(67, 98, 233) 0%, rgb(53, 79, 192) 100%)",
          }}
          className="adaptive-carousel-section-width-height adaptive-border-radius-default flex-justify-content-center flex-align-items-center flex-direction-column"
        >
          <CarouselSection windowInnerWidth={props.windowInnerWidth} />
        </div>
        <div className="adaptive-login-section-width-height flex-justify-content-center flex-direction-column">
          <LoginHeading />

          <div
            style={{
              paddingTop: "64px",
            }}
            className="flex-justify-content-center"
          >
            {isAuth ? (
              <>
                {!showScreen.success &&
                  !showScreen.upload &&
                  !isDataExists.exists &&
                  !isChecking && (
                    <>
                      <AadhaarOTPLogin
                        windowInnerWidth={props.windowInnerWidth}
                        phoneNumber={formData.phoneNumber}
                        setShowScreen={setShowScreen}
                        isAuth={isAuth}
                        setAadhaarFormData={setAadhaarFormData}
                        aadhaarFormData={aadhaarFormData}
                      />
                    </>
                  )}

                {isChecking && (
                  <div
                    style={{
                      height: "250px",
                      width: "100%",
                    }}
                    className="flex-align-items-center flex-justify-content-center"
                  >
                    <div className="background-color-white padding-left-default padding-right-default padding-top-small padding-bottom-small border-radius-default ">
                      <div className="qr-loader" data-cy="qr-loader" />
                    </div>
                  </div>
                )}

                {showScreen.success && !isChecking && (
                  <SuccessScreen
                    continueOnClick={() => {
                      window.location.reload();
                    }}
                    text={
                      <>
                        <div>Resume Uploaded!</div>
                        <p className="font-size-medium display-flex padding-top-default">
                          You can follow
                          <span
                            onClick={() => {
                              openInNewTab(citizen.baseUrl);
                            }}
                            className="cursor-pointer font-color-primary padding-left-small padding-right-small flex-justify-content-center"
                          >
                            Arun Nehru App
                            <ShareIcon />
                          </span>
                          for further
                        </p>
                        <p className="font-size-medium flex-align-items-center flex-justify-content-center">
                          job opportunities and much more!
                        </p>
                      </>
                    }
                    buttonText="CONFIRM"
                    downloadText={
                      <div className="text-align-center font-size-medium">
                        <span
                          onClick={() => {
                            if (downloadURL.url) {
                              downloadFile(downloadURL.url);
                            }
                          }}
                          className="font-color-primary"
                        >
                          Click here
                        </span>{" "}
                        to view your uploaded resume
                      </div>
                    }
                  />
                )}
                {showScreen.upload && !isChecking && (
                  <ResumeUpload
                    setSnackBarData={(data) => {
                      props.setSnackBarData(data);
                    }}
                    setDownloadURL={setDownloadURL}
                    file={file}
                    setFile={setFile}
                    setShowScreen={setShowScreen}
                    phoneNumber={formData.phoneNumber}
                    removeImageOnClick={() => {
                      setFile({
                        document: [],
                      });
                    }}
                  />
                )}
              </>
            ) : (
              <OTPLogin
                setSnackBarData={(data) => {
                  props.setSnackBarData(data);
                }}
                windowInnerWidth={props.windowInnerWidth}
                setFormData={setFormData}
                setIsAuth={setIsAuth}
                formData={formData}
              />
            )}
          </div>
        </div>
      </div>
      <footer
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.06) 0%, rgb(255, 255, 255) 100%)",
        }}
        className="padding-larger"
      >
        <div
          className="padding-bottom-larger adaptive-flex flex-justify-content-space-between"
          style={{
            borderTopWidth: "0px",
            borderLeftWidth: "0px",
            borderRightWidth: "0px",
            borderBottomWidth: "1px",
            borderColor: "rgba(0, 0, 0, 0.16)",
            borderStyle: "solid",
            width: "100%",
            height: "auto",
          }}
        >
          <div className="adaptive-width-50-percentage padding-top-larger padding-bottom-larger">
            <div
              style={{
                fontSize: "32px",
                fontWeight: "600",
              }}
              className="text-align-left font-family-public-sans"
            >
              Get in touch
            </div>
            <div>
              {[
                { icon: <EmailIcon />, value: "contact@arunnehru.com" },
                // { icon: <PhoneIcon />, value: "+91 99999 99999" },
                {
                  icon: <AddressIcon />,
                  value:
                    "274/C, Thuraiyur - Perambalur Rd, Sungu Pettai, Perambalur, Tamil Nadu 621212",
                },
              ].map((data, index) => (
                <div key={index}>
                  <Fields icon={data.icon} value={data.value} />
                </div>
              ))}
            </div>
          </div>
          <div className="adaptive-width-50-percentage flex-direction-column flex-align-items-end">
            <img
              style={{
                height: "200px",
                objectFit: "cover",
              }}
              onClick={() => {
                openInNewTab(
                  "https://www.google.com/maps/place/Dhanalakshmi+Srinivasan+College+Of+Engineering+And+Technology/@11.2383654,78.8341798,16z/data=!4m6!3m5!1s0x3bab1b655e8fd191:0x39c383554eeca977!8m2!3d11.2369487!4d78.8390343!16s%2Fg%2F11h7rrdx97"
                );
              }}
              className="adaptive-map-width"
              src={mapImage}
              alt="map"
            />
          </div>
        </div>
        <div className="padding-top-larger adaptive-flex flex-justify-content-space-between flex-align-items-center">
          <div
            className="text-align-center"
            style={{
              fontSize: "14px",
            }}
          >
            <span
              style={{
                color: "#888888",
                fontSize: "16px",
              }}
            >
              &#169;
            </span>
            2024 Origin.25. All Rights Reserved.
          </div>
          <div className="display-flex adaptive-padding-top adaptive-align">
            <div
              style={{
                height: "20px",
                width: "20px",
              }}
            >
              <LanguageIcon />
            </div>
            <div
              style={{
                fontSize: "16px",
              }}
              className="padding-left-default"
            >
              English
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

const Fields = (props) => {
  return (
    <div className="display-flex flex-align-items-center padding-top-default">
      <div
        style={{
          minWidth: "20px",
          maxHeight: "20px",
          minHeight: "20px",
          maxWidth: "20px",
        }}
      >
        {props.icon}
      </div>
      <div
        style={{
          opacity: "80%",
        }}
        className="padding-left-large"
      >
        {props.value}
      </div>
    </div>
  );
};

const CarouselSection = (props) => {
  return (
    <>
      <div className="display-flex flex-justify-content-center">
        <img src={arunNehruProfileImage} alt="" />
      </div>
      <div className="font-color-white display-flex flex-direction-column flex-justify-content-center font-family-public-sans">
        {props.windowInnerWidth <= 767 ? (
          <>
            <div
              style={{
                fontSize: "32px",
              }}
              className="text-align-center"
            >
              Perambalur
            </div>
            <div
              style={{
                fontSize: "32px",
                fontWeight: "600",
              }}
              className="text-align-center"
            >
              Job Fair 2024
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                fontSize: "32px",
              }}
              className="text-align-center"
            >
              Perambalur{" "}
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                Job Fair 2024
              </span>
            </div>
          </>
        )}
        <div
          style={{
            fontSize: "14px",
            letterSpacing: "3px",
          }}
          className="text-align-center padding-top-default font-family-space-grotesk"
        >
          ORGANIZED BY HON. MP{" "}
          <span
            style={{
              fontWeight: "600",
            }}
          >
            ARUN NEHRU
          </span>
        </div>
      </div>
      <div
        style={{
          paddingTop: "48px",
        }}
        className="flex-justify-content-center align-content-center inherit-parent-width"
      >
        <div className="adaptive-carousel-height adaptive-carousel-width">
          <Carousel
            autoPlay={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={60}
            centerSlidePercentage={50}
            infiniteLoop={true}
            showArrows={props.windowInnerWidth <= 767 ? false : true}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
          >
            <div
              style={{
                paddingRight: "8px",
                paddingLeft: "8px",
                borderRadius: "2px",
              }}
              className="adaptive-carousel-height"
            >
              <img
                decoding="async"
                loading="lazy"
                src={props.windowInnerWidth <= 767 ? image1pc : image1mob}
                alt=""
                className="adaptive-carousel-image1-height"
                style={{
                  display: "block",
                  borderRadius: "2px",
                  objectPosition: "center center",
                  objectFit:
                    props.windowInnerWidth <= 767 ? "contain" : "contain",
                  imageRendering: "auto",
                }}
              />
              <p className=" p-adaptive-font-size font-color-white font-family-public-sans text-align-center padding-top-large">
                Good luck out there! We wish you to find the perfect opportunity
                that ignites your passion and propels your career forward!!
              </p>
            </div>

            <div
              style={{
                paddingRight: "8px",
                paddingLeft: "8px",
                borderRadius: "2px",
              }}
              className="adaptive-carousel-height"
            >
              <img
                decoding="async"
                loading="lazy"
                src={props.windowInnerWidth <= 767 ? image1TamMob : image1TamPc}
                alt=""
                className="adaptive-carousel-image1-height"
                style={{
                  display: "block",
                  borderRadius: "2px",
                  objectPosition: "center center",
                  objectFit:
                    props.windowInnerWidth <= 767 ? "contain" : "contain",
                  imageRendering: "auto",
                }}
              />
              <p className=" p-adaptive-font-size font-color-white font-family-public-sans text-align-center padding-top-large">
                சிறந்த வாய்ப்பைக் நீங்கள் கண்டறிய வேண்டும் என்று நாங்கள்
                விரும்புகிறோம்!!
              </p>
            </div>

            <div
              style={{
                ...(props.windowInnerWidth <= 767
                  ? { height: "580px" }
                  : { height: "90%" }),
                paddingRight: "8px",
                paddingLeft: "8px",
              }}
              className="adaptive-flex"
            >
              {props.windowInnerWidth <= 767 ? (
                <>
                  <div
                    style={{
                      borderRadius: "2px",
                    }}
                    className="adaptive-width-50-percentage adaptive-carousel-image2-height"
                  >
                    <img
                      decoding="async"
                      loading="lazy"
                      src={image2}
                      alt=""
                      className="adaptive-carousel-image2-height"
                      style={{
                        display: "block",
                        borderRadius: "2px",
                        objectPosition: "center center",
                        objectFit: "cover",
                        imageRendering: "auto",
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`adaptive-width-50-percentage font-color-white ${
                      props.windowInnerWidth <= 767
                        ? ""
                        : "padding-right-larger"
                    } flex-justify-content-center flex-direction-column`}
                  >
                    <p
                      style={{
                        fontWeight: "600",
                      }}
                      className="p1-adaptive-font-size font-family-public-sans text-align-left"
                    >
                      Join Us at the Job Fair Hosted by Hon. MP Mr. Arun Nehru!
                    </p>
                    <br />
                    <p className="p-adaptive-font-size font-family-public-sans text-align-left">
                      Don't miss this exciting opportunity to connect with top
                      employers, explore job openings, and find the career path
                      that’s right for you. Whether you're interested in
                      Manufacturing, IT, or Labor, there's something for
                      everyone!
                    </p>
                    <br />
                    <p className="p-adaptive-font-size font-family-public-sans text-align-left">
                      Bring your resume, dress to impress, and get ready to make
                      lasting connections that could advance your career. See
                      you there!
                    </p>
                    <br />
                  </div>
                </>
              )}
              {props.windowInnerWidth <= 767 ? (
                <>
                  <div
                    className={`adaptive-width-50-percentage font-color-white ${
                      props.windowInnerWidth <= 767
                        ? ""
                        : "padding-right-larger"
                    } flex-justify-content-center flex-direction-column`}
                  >
                    <br />
                    <p
                      style={{
                        fontWeight: "600",
                        paddingBottom: "8px",
                      }}
                      className="p1-adaptive-font-size font-family-public-sans text-align-left"
                    >
                      Join Us at the Job Fair Hosted by Hon. MP Mr. Arun Nehru!
                    </p>
                    <p
                      style={{
                        paddingBottom: "8px",
                      }}
                      className="p-adaptive-font-size font-family-public-sans text-align-left"
                    >
                      Don't miss this exciting opportunity to connect with top
                      employers, explore job openings, and find the career path
                      that’s right for you. Whether you're interested in
                      Manufacturing, IT, or Labor, there's something for
                      everyone!
                    </p>
                    <p
                      style={{
                        paddingBottom: "8px",
                      }}
                      className="p-adaptive-font-size font-family-public-sans text-align-left"
                    >
                      Bring your resume, dress to impress, and get ready to make
                      lasting connections that could advance your career. See
                      you there!
                    </p>
                    <br />
                  </div>
                </>
              ) : (
                <div
                  style={{
                    borderRadius: "2px",
                  }}
                  className="adaptive-width-50-percentage adaptive-carousel-image2-height"
                >
                  <img
                    decoding="async"
                    loading="lazy"
                    src={image2}
                    alt=""
                    className="adaptive-carousel-image2-height"
                    style={{
                      display: "block",
                      borderRadius: "inherit",
                      objectPosition: "center center",
                      objectFit: "cover",
                      imageRendering: "auto",
                    }}
                  />
                </div>
              )}
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

const LoginHeading = () => {
  return (
    <>
      <div
        style={{
          fontSize: "32px",
          fontWeight: "600",
        }}
        className="text-align-center adaptive-padding-top font-family-public-sans"
      >
        Register for free!
      </div>
      <div
        style={{
          fontSize: "14px",
          letterSpacing: "3px",
        }}
        className="text-align-center font-family-space-grotesk padding-top-default"
      >
        GET ENTRY TO THE JOB FAIR
      </div>
    </>
  );
};

function openInNewTab(url) {
  window.open(url, "_blank").focus();
}
