import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { database } from "./firebase";

export async function checkAccountExistAndIsResumePresent(phoneNumber) {
  try {
    const employeeRef = query(
      collection(database, "citizens"),
      where("phoneNumber", "==", phoneNumber)
    );

    const querySnapshot = await getDocs(employeeRef);
    if (!querySnapshot.empty) {
      const docData = querySnapshot.docs[0].data();
      return {
        exists: true,
        resume: docData.resumeUrl,
      };
    } else {
      return {
        exists: false,
        resume: false,
      };
    }
  } catch (error) {
    console.error("Error checking account existence:", error);
    return false;
  }
}

export async function getDocumentId(phoneNumber) {
  try {
    const employeeRef = query(
      collection(database, "citizens"),
      where("phoneNumber", "==", phoneNumber)
    );
    let data = {};

    const querySnapshot = await getDocs(employeeRef);
    querySnapshot.forEach((doc) => {
      data = {
        ...doc.data(),
        documentId: doc.id,
      };
    });
    return data.documentId;
  } catch (error) {
    console.error("Error checking account existence:", error);
    return false;
  }
}

export async function updateFileURLToCurrentUser(documentId, fileURL) {
  try {
    const employeeRef = doc(database, "citizens", documentId);
    await updateDoc(employeeRef, { resumeUrl: fileURL });
    console.log("uploaded successfully");
  } catch (error) {
    console.error("Error checking account existence:", error);
  }
}
